@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-feature-settings: "lnum" 1; /* Fix up the Raleway number alignment. */
}

a {
  color: #4285F4 !important;
  -webkit-text-decoration-color: #4285F4 !important;
          text-decoration-color: #4285F4 !important;
}

a:hover {
  -webkit-text-decoration-color: #4285F4 !important;
          text-decoration-color: #4285F4 !important;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tDMPKhSkFEkm8.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tMMPKhSkFEkm8.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tCMPKhSkFE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVFteOYktMqlap.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVGdeOYktMqlap.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVF9eOYktMqg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucXtAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTufntAOvWDSHFF.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z11lFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1JlFd2JQEl8qw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMRrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtM1rTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMVrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMprTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMZrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMdrTFcZZJmOpwVS.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v22/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMlrTFcZZJmOpw.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/**
 * Do not edit directly
 * Generated on Fri, 20 Sep 2024 07:42:41 GMT
 */

:root {
  --redsift-color-dark-components-page-background: #333333;
  --redsift-color-dark-components-text-primary: #ffffff;
  --redsift-color-dark-components-text-secondary: #f2f2f2;
  --redsift-color-dark-components-red-sift-logo-text-red: #ffffff;
  --redsift-color-dark-components-red-sift-logo-text-white: #ffffff;
  --redsift-color-dark-components-red-sift-logo-text-grey: #ffffff;
  --redsift-color-dark-components-red-sift-logo-icon-background: #ffffff;
  --redsift-color-dark-components-red-sift-logo-icon-r: #e11010;
  --redsift-color-dark-components-red-sift-logo-diamond-top-right: #ffb3b3;
  --redsift-color-dark-components-red-sift-logo-diamond-bottom-left: #e24e4e;
  --redsift-color-dark-components-red-sift-logo-diamond-bottom-right: #ff8181;
  --redsift-color-dark-components-red-sift-logo-diamond-dark: #e24e4e;
  --redsift-color-dark-components-red-sift-logo-diamond-mid: #ff8181;
  --redsift-color-dark-components-red-sift-logo-diamond-light: #ffb3b3;
  --redsift-color-dark-components-product-logos-text-grey: #ffffff;
  --redsift-color-dark-components-product-logos-text-red: #ffffff;
  --redsift-color-dark-components-product-logos-text-white: #ffffff;
  --redsift-color-dark-components-product-logos-icon-red: #e11010;
  --redsift-color-dark-components-product-logos-icon-grey: #ffffff;
  --redsift-color-dark-components-side-navigation-background: #474747;
  --redsift-color-dark-components-side-navigation-menu-item-text-resting: #f2f2f2;
  --redsift-color-dark-components-side-navigation-menu-item-text-disabled: #666666;
  --redsift-color-dark-components-side-navigation-menu-item-text-hover: #ffffff;
  --redsift-color-dark-components-side-navigation-menu-item-background-hover: #666666;
  --redsift-color-dark-components-side-navigation-menu-item-background-secondary: transparent;
  --redsift-color-dark-components-side-navigation-menu-item-background-active: #e0e0e0;
  --redsift-color-dark-components-side-navigation-menu-item-active: #1c1c1c;
  --redsift-color-dark-components-side-navigation-scrollbar-resting: #666666;
  --redsift-color-dark-components-side-navigation-scrollbar-hover: #666666;
  --redsift-color-dark-components-side-navigation-right-line: #1c1c1c;
  --redsift-color-dark-components-side-navigation-current-marker: #f2f2f2;
  --redsift-color-dark-components-app-bar-background: #333333;
  --redsift-color-dark-components-app-bar-breadcrumb-default: #ffffff;
  --redsift-color-dark-components-app-bar-breadcrumb-hover: #142849;
  --redsift-color-dark-components-app-bar-breadcrumb-down: #0d1b31;
  --redsift-color-dark-components-app-bar-icon-background-hover: #666666;
  --redsift-color-dark-components-app-bar-icon-background-down: #e0e0e0;
  --redsift-color-dark-components-app-bar-icon-background-active: #666666;
  --redsift-color-dark-components-app-bar-icon-background-active-hover: #e0e0e0;
  --redsift-color-dark-components-app-bar-icon-background-active-down: #e0e0e0;
  --redsift-color-dark-components-app-bar-border: #1c1c1c;
  --redsift-color-dark-components-tooltip-background: #666666;
  --redsift-color-dark-components-tooltip-text: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-primary-background-default: #4285f4;
  --redsift-color-dark-components-buttons-primary-button-primary-background-hover: #3b78dc;
  --redsift-color-dark-components-buttons-primary-button-primary-background-active: #2e5dab;
  --redsift-color-dark-components-buttons-primary-button-primary-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-primary-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-primary-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-primary-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-primary-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-secondary-background-default: #51b7a4;
  --redsift-color-dark-components-buttons-primary-button-secondary-background-hover: #49a594;
  --redsift-color-dark-components-buttons-primary-button-secondary-background-active: #398073;
  --redsift-color-dark-components-buttons-primary-button-secondary-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-secondary-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-secondary-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-secondary-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-secondary-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-success-background-default: #02ac61;
  --redsift-color-dark-components-buttons-primary-button-success-background-hover: #029b57;
  --redsift-color-dark-components-buttons-primary-button-success-background-active: #017844;
  --redsift-color-dark-components-buttons-primary-button-success-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-success-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-success-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-success-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-success-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-error-background-default: #e11010;
  --redsift-color-dark-components-buttons-primary-button-error-background-hover: #cb0e0e;
  --redsift-color-dark-components-buttons-primary-button-error-background-active: #9e0b0b;
  --redsift-color-dark-components-buttons-primary-button-error-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-error-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-error-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-error-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-error-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-warning-background-default: #fcbb54;
  --redsift-color-dark-components-buttons-primary-button-warning-background-hover: #e3a84c;
  --redsift-color-dark-components-buttons-primary-button-warning-background-active: #b0833b;
  --redsift-color-dark-components-buttons-primary-button-warning-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-warning-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-warning-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-warning-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-warning-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-info-background-default: #4285f4;
  --redsift-color-dark-components-buttons-primary-button-info-background-hover: #3b78dc;
  --redsift-color-dark-components-buttons-primary-button-info-background-active: #2e5dab;
  --redsift-color-dark-components-buttons-primary-button-info-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-info-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-info-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-info-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-info-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-grey-background-default: #666666;
  --redsift-color-dark-components-buttons-primary-button-grey-background-hover: #5c5c5c;
  --redsift-color-dark-components-buttons-primary-button-grey-background-active: #525252;
  --redsift-color-dark-components-buttons-primary-button-grey-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-grey-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-grey-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-grey-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-grey-text-disabled: #858585;
  --redsift-color-dark-components-buttons-primary-button-radar-background-default: linear-gradient(
    90deg,
    rgba(81, 183, 164, 1),
    rgba(0, 129, 195, 1)
  );
  --redsift-color-dark-components-buttons-primary-button-radar-background-hover: linear-gradient(
    90deg,
    rgba(65, 146, 131, 1),
    rgba(3, 114, 171, 1)
  );
  --redsift-color-dark-components-buttons-primary-button-radar-background-active: linear-gradient(
    90deg,
    rgba(58, 122, 110, 1),
    rgba(0, 96, 145, 1)
  );
  --redsift-color-dark-components-buttons-primary-button-radar-background-disabled: #474747;
  --redsift-color-dark-components-buttons-primary-button-radar-text-default: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-radar-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-radar-text-active: #ffffff;
  --redsift-color-dark-components-buttons-primary-button-radar-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-primary-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-primary-background-hover: #142849;
  --redsift-color-dark-components-buttons-secondary-button-primary-background-active: #0d1b31;
  --redsift-color-dark-components-buttons-secondary-button-primary-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-primary-text-default: #4285f4;
  --redsift-color-dark-components-buttons-secondary-button-primary-text-hover: #689df6;
  --redsift-color-dark-components-buttons-secondary-button-primary-text-active: #8eb6f8;
  --redsift-color-dark-components-buttons-secondary-button-primary-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-secondary-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-secondary-background-hover: #183731;
  --redsift-color-dark-components-buttons-secondary-button-secondary-background-active: #102521;
  --redsift-color-dark-components-buttons-secondary-button-secondary-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-secondary-text-default: #51b7a4;
  --redsift-color-dark-components-buttons-secondary-button-secondary-text-hover: #74c5b6;
  --redsift-color-dark-components-buttons-secondary-button-secondary-text-active: #97d4c8;
  --redsift-color-dark-components-buttons-secondary-button-secondary-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-success-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-success-background-hover: #01341d;
  --redsift-color-dark-components-buttons-secondary-button-success-background-active: #002213;
  --redsift-color-dark-components-buttons-secondary-button-success-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-success-text-default: #02ac61;
  --redsift-color-dark-components-buttons-secondary-button-success-text-hover: #35bd81;
  --redsift-color-dark-components-buttons-secondary-button-success-text-active: #67cda0;
  --redsift-color-dark-components-buttons-secondary-button-success-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-error-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-error-background-hover: #430505;
  --redsift-color-dark-components-buttons-secondary-button-error-background-active: #2d0303;
  --redsift-color-dark-components-buttons-secondary-button-error-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-error-text-default: #e11010;
  --redsift-color-dark-components-buttons-secondary-button-error-text-hover: #e74040;
  --redsift-color-dark-components-buttons-secondary-button-error-text-active: #ed7070;
  --redsift-color-dark-components-buttons-secondary-button-error-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-warning-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-warning-background-hover: #4c3819;
  --redsift-color-dark-components-buttons-secondary-button-warning-background-active: #322511;
  --redsift-color-dark-components-buttons-secondary-button-warning-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-warning-text-default: #fcbb54;
  --redsift-color-dark-components-buttons-secondary-button-warning-text-hover: #fdc976;
  --redsift-color-dark-components-buttons-secondary-button-warning-text-active: #fdd698;
  --redsift-color-dark-components-buttons-secondary-button-warning-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-info-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-info-background-hover: #142849;
  --redsift-color-dark-components-buttons-secondary-button-info-background-active: #0d1b31;
  --redsift-color-dark-components-buttons-secondary-button-info-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-info-text-default: #4285f4;
  --redsift-color-dark-components-buttons-secondary-button-info-text-hover: #689df6;
  --redsift-color-dark-components-buttons-secondary-button-info-text-active: #8eb6f8;
  --redsift-color-dark-components-buttons-secondary-button-info-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-grey-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-grey-background-hover: #525252;
  --redsift-color-dark-components-buttons-secondary-button-grey-background-active: #333333;
  --redsift-color-dark-components-buttons-secondary-button-grey-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-grey-text-default: #666666;
  --redsift-color-dark-components-buttons-secondary-button-grey-text-hover: #858585;
  --redsift-color-dark-components-buttons-secondary-button-grey-text-active: #a3a3a3;
  --redsift-color-dark-components-buttons-secondary-button-grey-text-disabled: #858585;
  --redsift-color-dark-components-buttons-secondary-button-black-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-black-background-hover: #525252;
  --redsift-color-dark-components-buttons-secondary-button-black-background-active: #333333;
  --redsift-color-dark-components-buttons-secondary-button-black-background-disabled: transparent;
  --redsift-color-dark-components-buttons-secondary-button-black-text-default: #333333;
  --redsift-color-dark-components-buttons-secondary-button-black-text-hover: #333333;
  --redsift-color-dark-components-buttons-secondary-button-black-text-active: #333333;
  --redsift-color-dark-components-buttons-secondary-button-black-text-disabled: #a3a3a3;
  --redsift-color-dark-components-buttons-secondary-button-white-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-white-background-hover: #525252;
  --redsift-color-dark-components-buttons-secondary-button-white-background-active: #333333;
  --redsift-color-dark-components-buttons-secondary-button-white-background-disabled: transparent;
  --redsift-color-dark-components-buttons-secondary-button-white-text-default: #ffffff;
  --redsift-color-dark-components-buttons-secondary-button-white-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-secondary-button-white-text-active: #ffffff;
  --redsift-color-dark-components-buttons-secondary-button-white-text-disabled: #a3a3a3;
  --redsift-color-dark-components-buttons-secondary-button-radar-background-default: transparent;
  --redsift-color-dark-components-buttons-secondary-button-radar-background-hover: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.2),
    rgba(0, 129, 195, 0.2)
  );
  --redsift-color-dark-components-buttons-secondary-button-radar-background-active: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.4),
    rgba(0, 129, 195, 0.4)
  );
  --redsift-color-dark-components-buttons-secondary-button-radar-background-disabled: #333333;
  --redsift-color-dark-components-buttons-secondary-button-radar-text-default: linear-gradient(
    90deg,
    rgba(81, 183, 164, 1),
    rgba(0, 129, 195, 1)
  );
  --redsift-color-dark-components-buttons-secondary-button-radar-text-hover: linear-gradient(
    90deg,
    rgba(65, 146, 131, 1),
    rgba(3, 114, 171, 1)
  );
  --redsift-color-dark-components-buttons-secondary-button-radar-text-active: linear-gradient(
    90deg,
    rgba(58, 122, 110, 1),
    rgba(0, 96, 145, 1)
  );
  --redsift-color-dark-components-buttons-secondary-button-radar-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-primary-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-primary-background-hover: #142849;
  --redsift-color-dark-components-buttons-unstyled-button-primary-background-active: #0d1b31;
  --redsift-color-dark-components-buttons-unstyled-button-primary-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-primary-text-default: #4285f4;
  --redsift-color-dark-components-buttons-unstyled-button-primary-text-hover: #689df6;
  --redsift-color-dark-components-buttons-unstyled-button-primary-text-active: #8eb6f8;
  --redsift-color-dark-components-buttons-unstyled-button-primary-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-background-hover: #183731;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-background-active: #102521;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-text-default: #51b7a4;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-text-hover: #74c5b6;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-text-active: #97d4c8;
  --redsift-color-dark-components-buttons-unstyled-button-secondary-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-success-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-success-background-hover: #01341d;
  --redsift-color-dark-components-buttons-unstyled-button-success-background-active: #002213;
  --redsift-color-dark-components-buttons-unstyled-button-success-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-success-text-default: #02ac61;
  --redsift-color-dark-components-buttons-unstyled-button-success-text-hover: #35bd81;
  --redsift-color-dark-components-buttons-unstyled-button-success-text-active: #67cda0;
  --redsift-color-dark-components-buttons-unstyled-button-success-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-error-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-error-background-hover: #430505;
  --redsift-color-dark-components-buttons-unstyled-button-error-background-active: #2d0303;
  --redsift-color-dark-components-buttons-unstyled-button-error-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-error-text-default: #e11010;
  --redsift-color-dark-components-buttons-unstyled-button-error-text-hover: #e74040;
  --redsift-color-dark-components-buttons-unstyled-button-error-text-active: #ed7070;
  --redsift-color-dark-components-buttons-unstyled-button-error-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-warning-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-warning-background-hover: #4c3819;
  --redsift-color-dark-components-buttons-unstyled-button-warning-background-active: #322511;
  --redsift-color-dark-components-buttons-unstyled-button-warning-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-warning-text-default: #fcbb54;
  --redsift-color-dark-components-buttons-unstyled-button-warning-text-hover: #fdc976;
  --redsift-color-dark-components-buttons-unstyled-button-warning-text-active: #fdd698;
  --redsift-color-dark-components-buttons-unstyled-button-warning-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-info-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-info-background-hover: #142849;
  --redsift-color-dark-components-buttons-unstyled-button-info-background-active: #0d1b31;
  --redsift-color-dark-components-buttons-unstyled-button-info-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-info-text-default: #4285f4;
  --redsift-color-dark-components-buttons-unstyled-button-info-text-hover: #689df6;
  --redsift-color-dark-components-buttons-unstyled-button-info-text-active: #8eb6f8;
  --redsift-color-dark-components-buttons-unstyled-button-info-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-grey-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-grey-background-hover: #525252;
  --redsift-color-dark-components-buttons-unstyled-button-grey-background-active: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-grey-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-grey-text-default: #666666;
  --redsift-color-dark-components-buttons-unstyled-button-grey-text-hover: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-grey-text-active: #a3a3a3;
  --redsift-color-dark-components-buttons-unstyled-button-grey-text-disabled: #858585;
  --redsift-color-dark-components-buttons-unstyled-button-black-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-black-background-hover: #525252;
  --redsift-color-dark-components-buttons-unstyled-button-black-background-active: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-black-background-disabled: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-black-text-default: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-black-text-hover: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-black-text-active: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-black-text-disabled: #a3a3a3;
  --redsift-color-dark-components-buttons-unstyled-button-white-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-white-background-hover: #525252;
  --redsift-color-dark-components-buttons-unstyled-button-white-background-active: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-white-background-disabled: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-white-text-default: #ffffff;
  --redsift-color-dark-components-buttons-unstyled-button-white-text-hover: #ffffff;
  --redsift-color-dark-components-buttons-unstyled-button-white-text-active: #ffffff;
  --redsift-color-dark-components-buttons-unstyled-button-white-text-disabled: #a3a3a3;
  --redsift-color-dark-components-buttons-unstyled-button-radar-background-default: transparent;
  --redsift-color-dark-components-buttons-unstyled-button-radar-background-hover: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.2),
    rgba(0, 129, 195, 0.2)
  );
  --redsift-color-dark-components-buttons-unstyled-button-radar-background-active: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.4),
    rgba(0, 129, 195, 0.4)
  );
  --redsift-color-dark-components-buttons-unstyled-button-radar-background-disabled: #333333;
  --redsift-color-dark-components-buttons-unstyled-button-radar-text-default: linear-gradient(
    90deg,
    rgba(81, 183, 164, 1),
    rgba(0, 129, 195, 1)
  );
  --redsift-color-dark-components-buttons-unstyled-button-radar-text-hover: linear-gradient(
    90deg,
    rgba(65, 146, 131, 1),
    rgba(3, 114, 171, 1)
  );
  --redsift-color-dark-components-buttons-unstyled-button-radar-text-active: linear-gradient(
    90deg,
    rgba(58, 122, 110, 1),
    rgba(0, 96, 145, 1)
  );
  --redsift-color-dark-components-buttons-unstyled-button-radar-text-disabled: #858585;
  --redsift-color-dark-components-ai-ai-border: #e11010;
  --redsift-color-dark-components-ai-ai-icon: #e11010;
  --redsift-color-dark-components-spinner-spinner: #142849;
  --redsift-color-dark-components-alert-success-background: #029b57;
  --redsift-color-dark-components-alert-success-border: #017844;
  --redsift-color-dark-components-alert-success-icon: #017844;
  --redsift-color-dark-components-alert-text: #f2f2f2;
  --redsift-color-dark-components-alert-error-background: #cb0e0e;
  --redsift-color-dark-components-alert-error-border: #9e0b0b;
  --redsift-color-dark-components-alert-error-icon: #9e0b0b;
  --redsift-color-dark-components-alert-warning-background: #e3a84c;
  --redsift-color-dark-components-alert-warning-border: #b0833b;
  --redsift-color-dark-components-alert-warning-icon: #b0833b;
  --redsift-color-dark-components-alert-info-background: #3b78dc;
  --redsift-color-dark-components-alert-info-border: #2e5dab;
  --redsift-color-dark-components-alert-info-icon: #2e5dab;
  --redsift-color-dark-components-icons-default: #f2f2f2;
  --redsift-color-dark-components-dropdowns-and-menus-default-background: #474747;
  --redsift-color-dark-components-dropdowns-and-menus-default-text: #ffffff;
  --redsift-color-dark-components-dropdowns-and-menus-dividers: #666666;
  --redsift-color-dark-components-dropdowns-and-menus-hover-background: #666666;
  --redsift-color-dark-components-dropdowns-and-menus-hover-text: #ffffff;
  --redsift-color-dark-components-dropdowns-and-menus-click-background: #e0e0e0;
  --redsift-color-dark-components-dropdowns-and-menus-click-text: #1c1c1c;
  --redsift-color-dark-components-dropdowns-and-menus-toggle-active-text: #ffffff;
  --redsift-color-dark-components-dropdowns-and-menus-toggle-active-background: #4285f4;
  --redsift-color-dark-components-dropdowns-and-menus-toggle-active-hover-text: #ffffff;
  --redsift-color-dark-components-dropdowns-and-menus-toggle-active-hover-background: #3b78dc;
  --redsift-color-dark-components-dropdowns-and-menus-toggle-active-click-text: #1c1c1c;
  --redsift-color-dark-components-dropdowns-and-menus-toggle-active-click-background: #e0e0e0;
  --redsift-color-dark-components-checkbox-fill-default: #4285f4;
  --redsift-color-dark-components-checkbox-fill-default-hover: #142849;
  --redsift-color-dark-components-checkbox-fill-uncolored: #666666;
  --redsift-color-dark-components-checkbox-fill-uncolored-hover: #474747;
  --redsift-color-dark-components-checkbox-fill-disabled: #e0e0e0;
  --redsift-color-dark-components-checkbox-fill-invalid: #e11010;
  --redsift-color-dark-components-checkbox-fill-invalid-hover: #f39f9f;
  --redsift-color-dark-components-checkbox-text-default: #ffffff;
  --redsift-color-dark-components-checkbox-text-invalid: #e11010;
  --redsift-color-dark-components-checkbox-text-disabled: #e0e0e0;
  --redsift-color-dark-components-radio-fill-default: #4285f4;
  --redsift-color-dark-components-radio-fill-default-hover: #142849;
  --redsift-color-dark-components-radio-fill-uncolored: #666666;
  --redsift-color-dark-components-radio-fill-uncolored-hover: #474747;
  --redsift-color-dark-components-radio-fill-disabled: #e0e0e0;
  --redsift-color-dark-components-radio-fill-invalid: #e11010;
  --redsift-color-dark-components-radio-fill-invalid-hover: #f39f9f;
  --redsift-color-dark-components-radio-text-default: #ffffff;
  --redsift-color-dark-components-radio-text-invalid: #e11010;
  --redsift-color-dark-components-radio-text-disabled: #e0e0e0;
  --redsift-color-dark-components-switch-dot-default: #0d1b31;
  --redsift-color-dark-components-switch-dot-default-hover: #3b78dc;
  --redsift-color-dark-components-switch-dot-uncolored: #666666;
  --redsift-color-dark-components-switch-dot-uncolored-hover: #474747;
  --redsift-color-dark-components-switch-dot-disabled: #0d1b31;
  --redsift-color-dark-components-switch-dot-invalid: #0d1b31;
  --redsift-color-dark-components-switch-dot-invalid-hover: #f39f9f;
  --redsift-color-dark-components-switch-background-default: #3b78dc;
  --redsift-color-dark-components-switch-background-default-active: #4285f4;
  --redsift-color-dark-components-switch-background-uncolored: #474747;
  --redsift-color-dark-components-switch-background-uncolored-active: #666666;
  --redsift-color-dark-components-switch-background-disabled: #e0e0e0;
  --redsift-color-dark-components-switch-background-disabled-active: #e0e0e0;
  --redsift-color-dark-components-switch-background-invalid: #f39f9f;
  --redsift-color-dark-components-switch-background-invalid-active: #e11010;
  --redsift-color-dark-components-switch-text-default: #ffffff;
  --redsift-color-dark-components-switch-text-invalid: #e11010;
  --redsift-color-dark-components-switch-text-disabled: #e0e0e0;
  --redsift-color-dark-components-tabs-line: #666666;
  --redsift-color-dark-components-modal-background: #1c1c1c;
  --redsift-color-dark-components-pills-grey-default-background: #e6e6e6;
  --redsift-color-dark-components-pills-grey-default-border: unset;
  --redsift-color-dark-components-pills-grey-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-grey-hover-background: #d6d6d6;
  --redsift-color-dark-components-pills-grey-hover-border: unset;
  --redsift-color-dark-components-pills-grey-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-grey-disabled-background: #eeeeee;
  --redsift-color-dark-components-pills-grey-disabled-border: unset;
  --redsift-color-dark-components-pills-grey-disabled-text: #666666;
  --redsift-color-dark-components-pills-green-default-background: #a2e7ba;
  --redsift-color-dark-components-pills-green-default-border: unset;
  --redsift-color-dark-components-pills-green-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-green-hover-background: #56d58e;
  --redsift-color-dark-components-pills-green-hover-border: unset;
  --redsift-color-dark-components-pills-green-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-green-disabled-background: #c2efd1;
  --redsift-color-dark-components-pills-green-disabled-border: unset;
  --redsift-color-dark-components-pills-green-disabled-text: #666666;
  --redsift-color-dark-components-pills-pink-default-background: #eca0d6;
  --redsift-color-dark-components-pills-pink-default-border: unset;
  --redsift-color-dark-components-pills-pink-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-pink-hover-background: #d95cba;
  --redsift-color-dark-components-pills-pink-hover-border: unset;
  --redsift-color-dark-components-pills-pink-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-pink-disabled-background: #f4c0e3;
  --redsift-color-dark-components-pills-pink-disabled-border: unset;
  --redsift-color-dark-components-pills-pink-disabled-text: #666666;
  --redsift-color-dark-components-pills-aqua-default-background: #9af8f4;
  --redsift-color-dark-components-pills-aqua-default-border: unset;
  --redsift-color-dark-components-pills-aqua-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-aqua-hover-background: #63eae4;
  --redsift-color-dark-components-pills-aqua-hover-border: unset;
  --redsift-color-dark-components-pills-aqua-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-aqua-disabled-background: #caf7f4;
  --redsift-color-dark-components-pills-aqua-disabled-border: unset;
  --redsift-color-dark-components-pills-aqua-disabled-text: #666666;
  --redsift-color-dark-components-pills-brown-default-background: #edc19c;
  --redsift-color-dark-components-pills-brown-default-border: unset;
  --redsift-color-dark-components-pills-brown-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-brown-hover-background: #c78348;
  --redsift-color-dark-components-pills-brown-hover-border: unset;
  --redsift-color-dark-components-pills-brown-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-brown-disabled-background: #f6d9c3;
  --redsift-color-dark-components-pills-brown-disabled-border: unset;
  --redsift-color-dark-components-pills-brown-disabled-text: #666666;
  --redsift-color-dark-components-pills-red-default-background: #f3a39e;
  --redsift-color-dark-components-pills-red-default-border: unset;
  --redsift-color-dark-components-pills-red-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-red-hover-background: #e06363;
  --redsift-color-dark-components-pills-red-hover-border: unset;
  --redsift-color-dark-components-pills-red-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-red-disabled-background: #f8bbba;
  --redsift-color-dark-components-pills-red-disabled-border: unset;
  --redsift-color-dark-components-pills-red-disabled-text: #666666;
  --redsift-color-dark-components-pills-yellow-default-background: #f7efc3;
  --redsift-color-dark-components-pills-yellow-default-border: unset;
  --redsift-color-dark-components-pills-yellow-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-yellow-hover-background: #fff741;
  --redsift-color-dark-components-pills-yellow-hover-border: unset;
  --redsift-color-dark-components-pills-yellow-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-yellow-disabled-background: #fbf5db;
  --redsift-color-dark-components-pills-yellow-disabled-border: unset;
  --redsift-color-dark-components-pills-yellow-disabled-text: #666666;
  --redsift-color-dark-components-pills-purple-default-background: #c6a8ef;
  --redsift-color-dark-components-pills-purple-default-border: unset;
  --redsift-color-dark-components-pills-purple-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-purple-hover-background: #965ede;
  --redsift-color-dark-components-pills-purple-hover-border: unset;
  --redsift-color-dark-components-pills-purple-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-purple-disabled-background: #decaf6;
  --redsift-color-dark-components-pills-purple-disabled-border: unset;
  --redsift-color-dark-components-pills-purple-disabled-text: #666666;
  --redsift-color-dark-components-pills-orange-default-background: #f8d296;
  --redsift-color-dark-components-pills-orange-default-border: unset;
  --redsift-color-dark-components-pills-orange-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-orange-hover-background: #fcbb54;
  --redsift-color-dark-components-pills-orange-hover-border: unset;
  --redsift-color-dark-components-pills-orange-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-orange-disabled-background: #fde4c0;
  --redsift-color-dark-components-pills-orange-disabled-border: unset;
  --redsift-color-dark-components-pills-orange-disabled-text: #666666;
  --redsift-color-dark-components-pills-blue-default-background: #b1dcf3;
  --redsift-color-dark-components-pills-blue-default-border: unset;
  --redsift-color-dark-components-pills-blue-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-blue-hover-background: #73c5eb;
  --redsift-color-dark-components-pills-blue-hover-border: unset;
  --redsift-color-dark-components-pills-blue-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-blue-disabled-background: #cce8f7;
  --redsift-color-dark-components-pills-blue-disabled-border: unset;
  --redsift-color-dark-components-pills-blue-disabled-text: #666666;
  --redsift-color-dark-components-pills-black-default-background: #1c1c1c;
  --redsift-color-dark-components-pills-black-default-border: unset;
  --redsift-color-dark-components-pills-black-default-text: #ffffff;
  --redsift-color-dark-components-pills-black-hover-background: #333333;
  --redsift-color-dark-components-pills-black-hover-border: unset;
  --redsift-color-dark-components-pills-black-hover-text: #ffffff;
  --redsift-color-dark-components-pills-black-disabled-background: #474747;
  --redsift-color-dark-components-pills-black-disabled-border: unset;
  --redsift-color-dark-components-pills-black-disabled-text: #ffffff;
  --redsift-color-dark-components-pills-x-dark-grey-default-background: #333333;
  --redsift-color-dark-components-pills-x-dark-grey-default-border: unset;
  --redsift-color-dark-components-pills-x-dark-grey-default-text: #ffffff;
  --redsift-color-dark-components-pills-x-dark-grey-hover-background: #474747;
  --redsift-color-dark-components-pills-x-dark-grey-hover-border: unset;
  --redsift-color-dark-components-pills-x-dark-grey-hover-text: #ffffff;
  --redsift-color-dark-components-pills-x-dark-grey-disabled-background: #666666;
  --redsift-color-dark-components-pills-x-dark-grey-disabled-border: unset;
  --redsift-color-dark-components-pills-x-dark-grey-disabled-text: #ffffff;
  --redsift-color-dark-components-pills-dark-grey-default-background: #474747;
  --redsift-color-dark-components-pills-dark-grey-default-border: unset;
  --redsift-color-dark-components-pills-dark-grey-default-text: #ffffff;
  --redsift-color-dark-components-pills-dark-grey-hover-background: #666666;
  --redsift-color-dark-components-pills-dark-grey-hover-border: unset;
  --redsift-color-dark-components-pills-dark-grey-hover-text: #ffffff;
  --redsift-color-dark-components-pills-dark-grey-disabled-background: #e0e0e0;
  --redsift-color-dark-components-pills-dark-grey-disabled-border: unset;
  --redsift-color-dark-components-pills-dark-grey-disabled-text: #ffffff;
  --redsift-color-dark-components-pills-mid-grey-default-background: #666666;
  --redsift-color-dark-components-pills-mid-grey-default-border: unset;
  --redsift-color-dark-components-pills-mid-grey-default-text: #ffffff;
  --redsift-color-dark-components-pills-mid-grey-hover-background: #474747;
  --redsift-color-dark-components-pills-mid-grey-hover-border: unset;
  --redsift-color-dark-components-pills-mid-grey-hover-text: #ffffff;
  --redsift-color-dark-components-pills-mid-grey-disabled-background: #e0e0e0;
  --redsift-color-dark-components-pills-mid-grey-disabled-border: unset;
  --redsift-color-dark-components-pills-mid-grey-disabled-text: #666666;
  --redsift-color-dark-components-pills-light-grey-default-background: #e0e0e0;
  --redsift-color-dark-components-pills-light-grey-default-border: unset;
  --redsift-color-dark-components-pills-light-grey-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-light-grey-hover-background: #666666;
  --redsift-color-dark-components-pills-light-grey-hover-border: unset;
  --redsift-color-dark-components-pills-light-grey-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-light-grey-disabled-background: #f2f2f2;
  --redsift-color-dark-components-pills-light-grey-disabled-border: unset;
  --redsift-color-dark-components-pills-light-grey-disabled-text: #666666;
  --redsift-color-dark-components-pills-x-light-grey-default-background: #f2f2f2;
  --redsift-color-dark-components-pills-x-light-grey-default-border: unset;
  --redsift-color-dark-components-pills-x-light-grey-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-x-light-grey-hover-background: #e0e0e0;
  --redsift-color-dark-components-pills-x-light-grey-hover-border: unset;
  --redsift-color-dark-components-pills-x-light-grey-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-x-light-grey-disabled-background: #666666;
  --redsift-color-dark-components-pills-x-light-grey-disabled-border: unset;
  --redsift-color-dark-components-pills-x-light-grey-disabled-text: #666666;
  --redsift-color-dark-components-pills-white-default-background: #ffffff;
  --redsift-color-dark-components-pills-white-default-border: #e0e0e0;
  --redsift-color-dark-components-pills-white-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-white-hover-background: #f2f2f2;
  --redsift-color-dark-components-pills-white-hover-border: #e0e0e0;
  --redsift-color-dark-components-pills-white-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-white-disabled-background: #ffffff;
  --redsift-color-dark-components-pills-white-disabled-border: #e0e0e0;
  --redsift-color-dark-components-pills-white-disabled-text: #666666;
  --redsift-color-dark-components-pills-error-default-background: #f8bbba;
  --redsift-color-dark-components-pills-error-default-border: unset;
  --redsift-color-dark-components-pills-error-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-error-hover-background: #e06363;
  --redsift-color-dark-components-pills-error-hover-border: unset;
  --redsift-color-dark-components-pills-error-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-error-disabled-background: #f3a39e;
  --redsift-color-dark-components-pills-error-disabled-border: unset;
  --redsift-color-dark-components-pills-error-disabled-text: #666666;
  --redsift-color-dark-components-pills-warning-default-background: #fde4c0;
  --redsift-color-dark-components-pills-warning-default-border: unset;
  --redsift-color-dark-components-pills-warning-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-warning-hover-background: #fcbb54;
  --redsift-color-dark-components-pills-warning-hover-border: unset;
  --redsift-color-dark-components-pills-warning-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-warning-disabled-background: #f8d296;
  --redsift-color-dark-components-pills-warning-disabled-border: unset;
  --redsift-color-dark-components-pills-warning-disabled-text: #666666;
  --redsift-color-dark-components-pills-success-default-background: #c2efd1;
  --redsift-color-dark-components-pills-success-default-border: unset;
  --redsift-color-dark-components-pills-success-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-success-hover-background: #56d58e;
  --redsift-color-dark-components-pills-success-hover-border: unset;
  --redsift-color-dark-components-pills-success-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-success-disabled-background: #a2e7ba;
  --redsift-color-dark-components-pills-success-disabled-border: unset;
  --redsift-color-dark-components-pills-success-disabled-text: #666666;
  --redsift-color-dark-components-pills-error-dark-default-background: #e06363;
  --redsift-color-dark-components-pills-error-dark-default-border: unset;
  --redsift-color-dark-components-pills-error-dark-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-error-dark-hover-background: #de0000;
  --redsift-color-dark-components-pills-error-dark-hover-border: unset;
  --redsift-color-dark-components-pills-error-dark-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-error-dark-disabled-background: #f3a39e;
  --redsift-color-dark-components-pills-error-dark-disabled-border: unset;
  --redsift-color-dark-components-pills-error-dark-disabled-text: #666666;
  --redsift-color-dark-components-pills-warning-dark-default-background: #fcbb54;
  --redsift-color-dark-components-pills-warning-dark-default-border: unset;
  --redsift-color-dark-components-pills-warning-dark-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-warning-dark-hover-background: #ed9200;
  --redsift-color-dark-components-pills-warning-dark-hover-border: unset;
  --redsift-color-dark-components-pills-warning-dark-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-warning-dark-disabled-background: #f8d296;
  --redsift-color-dark-components-pills-warning-dark-disabled-border: unset;
  --redsift-color-dark-components-pills-warning-dark-disabled-text: #666666;
  --redsift-color-dark-components-pills-success-dark-default-background: #56d58e;
  --redsift-color-dark-components-pills-success-dark-default-border: unset;
  --redsift-color-dark-components-pills-success-dark-default-text: #1c1c1c;
  --redsift-color-dark-components-pills-success-dark-hover-background: #00ce59;
  --redsift-color-dark-components-pills-success-dark-hover-border: unset;
  --redsift-color-dark-components-pills-success-dark-hover-text: #1c1c1c;
  --redsift-color-dark-components-pills-success-dark-disabled-background: #a2e7ba;
  --redsift-color-dark-components-pills-success-dark-disabled-border: unset;
  --redsift-color-dark-components-pills-success-dark-disabled-text: #666666;
  --redsift-color-light-components-page-background: #ffffff;
  --redsift-color-light-components-text-primary: #333333;
  --redsift-color-light-components-text-secondary: #474747;
  --redsift-color-light-components-red-sift-logo-text-red: #e11010;
  --redsift-color-light-components-red-sift-logo-text-white: #ffffff;
  --redsift-color-light-components-red-sift-logo-text-grey: #333333;
  --redsift-color-light-components-red-sift-logo-icon-background: #e11010;
  --redsift-color-light-components-red-sift-logo-icon-r: #ffffff;
  --redsift-color-light-components-red-sift-logo-diamond-top-right: #e24e4e;
  --redsift-color-light-components-red-sift-logo-diamond-bottom-left: #ffb3b3;
  --redsift-color-light-components-red-sift-logo-diamond-bottom-right: #ff8181;
  --redsift-color-light-components-red-sift-logo-diamond-dark: #e24e4e;
  --redsift-color-light-components-red-sift-logo-diamond-mid: #ff8181;
  --redsift-color-light-components-red-sift-logo-diamond-light: #ffb3b3;
  --redsift-color-light-components-product-logos-text-grey: #333333;
  --redsift-color-light-components-product-logos-text-red: #e11010;
  --redsift-color-light-components-product-logos-text-white: #ffffff;
  --redsift-color-light-components-product-logos-icon-red: #e11010;
  --redsift-color-light-components-product-logos-icon-grey: #333333;
  --redsift-color-light-components-side-navigation-background: #f2f2f2;
  --redsift-color-light-components-side-navigation-menu-item-text-resting: #474747;
  --redsift-color-light-components-side-navigation-menu-item-text-disabled: #666666;
  --redsift-color-light-components-side-navigation-menu-item-text-hover: #1c1c1c;
  --redsift-color-light-components-side-navigation-menu-item-background-hover: #e6e6e6;
  --redsift-color-light-components-side-navigation-menu-item-background-secondary: transparent;
  --redsift-color-light-components-side-navigation-menu-item-background-active: #e0e0e0;
  --redsift-color-light-components-side-navigation-menu-item-active: #1c1c1c;
  --redsift-color-light-components-side-navigation-scrollbar-resting: #e0e0e0;
  --redsift-color-light-components-side-navigation-scrollbar-hover: #666666;
  --redsift-color-light-components-side-navigation-right-line: #e9e9e9;
  --redsift-color-light-components-side-navigation-current-marker: #1c1c1c;
  --redsift-color-light-components-app-bar-background: #ffffff;
  --redsift-color-light-components-app-bar-breadcrumb-default: #1c1c1c;
  --redsift-color-light-components-app-bar-breadcrumb-hover: #3b78dc;
  --redsift-color-light-components-app-bar-breadcrumb-down: #2e5dab;
  --redsift-color-light-components-app-bar-icon-background-hover: #f2f2f2;
  --redsift-color-light-components-app-bar-icon-background-down: #e0e0e0;
  --redsift-color-light-components-app-bar-icon-background-active: #f2f2f2;
  --redsift-color-light-components-app-bar-icon-background-active-hover: #e0e0e0;
  --redsift-color-light-components-app-bar-icon-background-active-down: #e0e0e0;
  --redsift-color-light-components-app-bar-border: #e9e9e9;
  --redsift-color-light-components-tooltip-background: #ffffff;
  --redsift-color-light-components-tooltip-text: #333333;
  --redsift-color-light-components-buttons-primary-button-primary-background-default: #4285f4;
  --redsift-color-light-components-buttons-primary-button-primary-background-hover: #3b78dc;
  --redsift-color-light-components-buttons-primary-button-primary-background-active: #2e5dab;
  --redsift-color-light-components-buttons-primary-button-primary-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-primary-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-primary-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-primary-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-primary-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-secondary-background-default: #51b7a4;
  --redsift-color-light-components-buttons-primary-button-secondary-background-hover: #49a594;
  --redsift-color-light-components-buttons-primary-button-secondary-background-active: #398073;
  --redsift-color-light-components-buttons-primary-button-secondary-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-secondary-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-secondary-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-secondary-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-secondary-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-success-background-default: #02ac61;
  --redsift-color-light-components-buttons-primary-button-success-background-hover: #029b57;
  --redsift-color-light-components-buttons-primary-button-success-background-active: #017844;
  --redsift-color-light-components-buttons-primary-button-success-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-success-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-success-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-success-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-success-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-error-background-default: #e11010;
  --redsift-color-light-components-buttons-primary-button-error-background-hover: #cb0e0e;
  --redsift-color-light-components-buttons-primary-button-error-background-active: #9e0b0b;
  --redsift-color-light-components-buttons-primary-button-error-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-error-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-error-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-error-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-error-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-warning-background-default: #fcbb54;
  --redsift-color-light-components-buttons-primary-button-warning-background-hover: #e3a84c;
  --redsift-color-light-components-buttons-primary-button-warning-background-active: #b0833b;
  --redsift-color-light-components-buttons-primary-button-warning-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-warning-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-warning-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-warning-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-warning-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-info-background-default: #4285f4;
  --redsift-color-light-components-buttons-primary-button-info-background-hover: #3b78dc;
  --redsift-color-light-components-buttons-primary-button-info-background-active: #2e5dab;
  --redsift-color-light-components-buttons-primary-button-info-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-info-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-info-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-info-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-info-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-grey-background-default: #666666;
  --redsift-color-light-components-buttons-primary-button-grey-background-hover: #5c5c5c;
  --redsift-color-light-components-buttons-primary-button-grey-background-active: #525252;
  --redsift-color-light-components-buttons-primary-button-grey-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-grey-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-grey-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-grey-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-grey-text-disabled: #858585;
  --redsift-color-light-components-buttons-primary-button-radar-background-default: linear-gradient(
    90deg,
    rgba(81, 183, 164, 1),
    rgba(0, 129, 195, 1)
  );
  --redsift-color-light-components-buttons-primary-button-radar-background-hover: linear-gradient(
    90deg,
    rgba(65, 146, 131, 1),
    rgba(3, 114, 171, 1)
  );
  --redsift-color-light-components-buttons-primary-button-radar-background-active: linear-gradient(
    90deg,
    rgba(58, 122, 110, 1),
    rgba(0, 96, 145, 1)
  );
  --redsift-color-light-components-buttons-primary-button-radar-background-disabled: #e0e0e0;
  --redsift-color-light-components-buttons-primary-button-radar-text-default: #ffffff;
  --redsift-color-light-components-buttons-primary-button-radar-text-hover: #ffffff;
  --redsift-color-light-components-buttons-primary-button-radar-text-active: #ffffff;
  --redsift-color-light-components-buttons-primary-button-radar-text-disabled: #858585;
  --redsift-color-light-components-buttons-secondary-button-primary-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-primary-background-hover: #d9e7fd;
  --redsift-color-light-components-buttons-secondary-button-primary-background-active: #b3cefb;
  --redsift-color-light-components-buttons-secondary-button-primary-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-primary-text-default: #4285f4;
  --redsift-color-light-components-buttons-secondary-button-primary-text-hover: #3b78dc;
  --redsift-color-light-components-buttons-secondary-button-primary-text-active: #2e5dab;
  --redsift-color-light-components-buttons-secondary-button-primary-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-secondary-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-secondary-background-hover: #dcf1ed;
  --redsift-color-light-components-buttons-secondary-button-secondary-background-active: #b9e2db;
  --redsift-color-light-components-buttons-secondary-button-secondary-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-secondary-text-default: #51b7a4;
  --redsift-color-light-components-buttons-secondary-button-secondary-text-hover: #49a594;
  --redsift-color-light-components-buttons-secondary-button-secondary-text-active: #398073;
  --redsift-color-light-components-buttons-secondary-button-secondary-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-success-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-success-background-hover: #cceedf;
  --redsift-color-light-components-buttons-secondary-button-success-background-active: #9adec0;
  --redsift-color-light-components-buttons-secondary-button-success-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-success-text-default: #02ac61;
  --redsift-color-light-components-buttons-secondary-button-success-text-hover: #029b57;
  --redsift-color-light-components-buttons-secondary-button-success-text-active: #017844;
  --redsift-color-light-components-buttons-secondary-button-success-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-error-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-error-background-hover: #f9cfcf;
  --redsift-color-light-components-buttons-secondary-button-error-background-active: #f39f9f;
  --redsift-color-light-components-buttons-secondary-button-error-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-error-text-default: #e11010;
  --redsift-color-light-components-buttons-secondary-button-error-text-hover: #cb0e0e;
  --redsift-color-light-components-buttons-secondary-button-error-text-active: #9e0b0b;
  --redsift-color-light-components-buttons-secondary-button-error-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-warning-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-warning-background-hover: #fef1dd;
  --redsift-color-light-components-buttons-secondary-button-warning-background-active: #fee4bb;
  --redsift-color-light-components-buttons-secondary-button-warning-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-warning-text-default: #fcbb54;
  --redsift-color-light-components-buttons-secondary-button-warning-text-hover: #e3a84c;
  --redsift-color-light-components-buttons-secondary-button-warning-text-active: #b0833b;
  --redsift-color-light-components-buttons-secondary-button-warning-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-info-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-info-background-hover: #d9e7fd;
  --redsift-color-light-components-buttons-secondary-button-info-background-active: #b3cefb;
  --redsift-color-light-components-buttons-secondary-button-info-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-info-text-default: #4285f4;
  --redsift-color-light-components-buttons-secondary-button-info-text-hover: #3b78dc;
  --redsift-color-light-components-buttons-secondary-button-info-text-active: #2e5dab;
  --redsift-color-light-components-buttons-secondary-button-info-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-grey-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-grey-background-hover: #e0e0e0;
  --redsift-color-light-components-buttons-secondary-button-grey-background-active: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-grey-background-disabled: transparent;
  --redsift-color-light-components-buttons-secondary-button-grey-text-default: #666666;
  --redsift-color-light-components-buttons-secondary-button-grey-text-hover: #525252;
  --redsift-color-light-components-buttons-secondary-button-grey-text-active: #525252;
  --redsift-color-light-components-buttons-secondary-button-grey-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-black-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-black-background-hover: #e0e0e0;
  --redsift-color-light-components-buttons-secondary-button-black-background-active: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-black-background-disabled: transparent;
  --redsift-color-light-components-buttons-secondary-button-black-text-default: #333333;
  --redsift-color-light-components-buttons-secondary-button-black-text-hover: #333333;
  --redsift-color-light-components-buttons-secondary-button-black-text-active: #333333;
  --redsift-color-light-components-buttons-secondary-button-black-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-white-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-white-background-hover: #e0e0e0;
  --redsift-color-light-components-buttons-secondary-button-white-background-active: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-white-background-disabled: transparent;
  --redsift-color-light-components-buttons-secondary-button-white-text-default: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-white-text-hover: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-white-text-active: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-white-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-secondary-button-radar-background-default: transparent;
  --redsift-color-light-components-buttons-secondary-button-radar-background-hover: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.2),
    rgba(0, 129, 195, 0.2)
  );
  --redsift-color-light-components-buttons-secondary-button-radar-background-active: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.4),
    rgba(0, 129, 195, 0.4)
  );
  --redsift-color-light-components-buttons-secondary-button-radar-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-secondary-button-radar-text-default: linear-gradient(
    90deg,
    rgba(81, 183, 164, 1),
    rgba(0, 129, 195, 1)
  );
  --redsift-color-light-components-buttons-secondary-button-radar-text-hover: linear-gradient(
    90deg,
    rgba(65, 146, 131, 1),
    rgba(3, 114, 171, 1)
  );
  --redsift-color-light-components-buttons-secondary-button-radar-text-active: linear-gradient(
    90deg,
    rgba(58, 122, 110, 1),
    rgba(0, 96, 145, 1)
  );
  --redsift-color-light-components-buttons-secondary-button-radar-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-primary-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-primary-background-hover: #d9e7fd;
  --redsift-color-light-components-buttons-unstyled-button-primary-background-active: #b3cefb;
  --redsift-color-light-components-buttons-unstyled-button-primary-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-primary-text-default: #4285f4;
  --redsift-color-light-components-buttons-unstyled-button-primary-text-hover: #3b78dc;
  --redsift-color-light-components-buttons-unstyled-button-primary-text-active: #2e5dab;
  --redsift-color-light-components-buttons-unstyled-button-primary-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-secondary-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-secondary-background-hover: #dcf1ed;
  --redsift-color-light-components-buttons-unstyled-button-secondary-background-active: #b9e2db;
  --redsift-color-light-components-buttons-unstyled-button-secondary-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-secondary-text-default: #51b7a4;
  --redsift-color-light-components-buttons-unstyled-button-secondary-text-hover: #49a594;
  --redsift-color-light-components-buttons-unstyled-button-secondary-text-active: #398073;
  --redsift-color-light-components-buttons-unstyled-button-secondary-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-success-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-success-background-hover: #cceedf;
  --redsift-color-light-components-buttons-unstyled-button-success-background-active: #9adec0;
  --redsift-color-light-components-buttons-unstyled-button-success-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-success-text-default: #02ac61;
  --redsift-color-light-components-buttons-unstyled-button-success-text-hover: #029b57;
  --redsift-color-light-components-buttons-unstyled-button-success-text-active: #017844;
  --redsift-color-light-components-buttons-unstyled-button-success-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-error-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-error-background-hover: #f9cfcf;
  --redsift-color-light-components-buttons-unstyled-button-error-background-active: #f39f9f;
  --redsift-color-light-components-buttons-unstyled-button-error-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-error-text-default: #e11010;
  --redsift-color-light-components-buttons-unstyled-button-error-text-hover: #cb0e0e;
  --redsift-color-light-components-buttons-unstyled-button-error-text-active: #9e0b0b;
  --redsift-color-light-components-buttons-unstyled-button-error-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-warning-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-warning-background-hover: #fef1dd;
  --redsift-color-light-components-buttons-unstyled-button-warning-background-active: #fee4bb;
  --redsift-color-light-components-buttons-unstyled-button-warning-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-warning-text-default: #fcbb54;
  --redsift-color-light-components-buttons-unstyled-button-warning-text-hover: #e3a84c;
  --redsift-color-light-components-buttons-unstyled-button-warning-text-active: #b0833b;
  --redsift-color-light-components-buttons-unstyled-button-warning-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-info-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-info-background-hover: #d9e7fd;
  --redsift-color-light-components-buttons-unstyled-button-info-background-active: #b3cefb;
  --redsift-color-light-components-buttons-unstyled-button-info-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-info-text-default: #4285f4;
  --redsift-color-light-components-buttons-unstyled-button-info-text-hover: #3b78dc;
  --redsift-color-light-components-buttons-unstyled-button-info-text-active: #2e5dab;
  --redsift-color-light-components-buttons-unstyled-button-info-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-grey-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-grey-background-hover: #e0e0e0;
  --redsift-color-light-components-buttons-unstyled-button-grey-background-active: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-grey-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-grey-text-default: #666666;
  --redsift-color-light-components-buttons-unstyled-button-grey-text-hover: #525252;
  --redsift-color-light-components-buttons-unstyled-button-grey-text-active: #525252;
  --redsift-color-light-components-buttons-unstyled-button-grey-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-black-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-black-background-hover: #e0e0e0;
  --redsift-color-light-components-buttons-unstyled-button-black-background-active: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-black-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-black-text-default: #333333;
  --redsift-color-light-components-buttons-unstyled-button-black-text-hover: #333333;
  --redsift-color-light-components-buttons-unstyled-button-black-text-active: #333333;
  --redsift-color-light-components-buttons-unstyled-button-black-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-white-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-white-background-hover: #e0e0e0;
  --redsift-color-light-components-buttons-unstyled-button-white-background-active: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-white-background-disabled: transparent;
  --redsift-color-light-components-buttons-unstyled-button-white-text-default: #ffffff;
  --redsift-color-light-components-buttons-unstyled-button-white-text-hover: #ffffff;
  --redsift-color-light-components-buttons-unstyled-button-white-text-active: #ffffff;
  --redsift-color-light-components-buttons-unstyled-button-white-text-disabled: #a3a3a3;
  --redsift-color-light-components-buttons-unstyled-button-radar-background-default: transparent;
  --redsift-color-light-components-buttons-unstyled-button-radar-background-hover: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.2),
    rgba(0, 129, 195, 0.2)
  );
  --redsift-color-light-components-buttons-unstyled-button-radar-background-active: linear-gradient(
    90deg,
    rgba(81, 183, 142, 0.4),
    rgba(0, 129, 195, 0.4)
  );
  --redsift-color-light-components-buttons-unstyled-button-radar-background-disabled: #ffffff;
  --redsift-color-light-components-buttons-unstyled-button-radar-text-default: linear-gradient(
    90deg,
    rgba(81, 183, 164, 1),
    rgba(0, 129, 195, 1)
  );
  --redsift-color-light-components-buttons-unstyled-button-radar-text-hover: linear-gradient(
    90deg,
    rgba(65, 146, 131, 1),
    rgba(3, 114, 171, 1)
  );
  --redsift-color-light-components-buttons-unstyled-button-radar-text-active: linear-gradient(
    90deg,
    rgba(58, 122, 110, 1),
    rgba(0, 96, 145, 1)
  );
  --redsift-color-light-components-buttons-unstyled-button-radar-text-disabled: #a3a3a3;
  --redsift-color-light-components-ai-ai-border: #e11010;
  --redsift-color-light-components-ai-ai-icon: #e11010;
  --redsift-color-light-components-spinner-spinner: #4285f4;
  --redsift-color-light-components-alert-success-background: #cceedf;
  --redsift-color-light-components-alert-success-border: #9adec0;
  --redsift-color-light-components-alert-success-icon: #02ac61;
  --redsift-color-light-components-alert-text: #333333;
  --redsift-color-light-components-alert-error-background: #f9cfcf;
  --redsift-color-light-components-alert-error-border: #f39f9f;
  --redsift-color-light-components-alert-error-icon: #e11010;
  --redsift-color-light-components-alert-warning-background: #fef1dd;
  --redsift-color-light-components-alert-warning-border: #fee4bb;
  --redsift-color-light-components-alert-warning-icon: #fcbb54;
  --redsift-color-light-components-alert-info-background: #d9e7fd;
  --redsift-color-light-components-alert-info-border: #b3cefb;
  --redsift-color-light-components-alert-info-icon: #4285f4;
  --redsift-color-light-components-icons-default: #333333;
  --redsift-color-light-components-dropdowns-and-menus-default-background: #ffffff;
  --redsift-color-light-components-dropdowns-and-menus-default-text: #333333;
  --redsift-color-light-components-dropdowns-and-menus-dividers: #e0e0e0;
  --redsift-color-light-components-dropdowns-and-menus-hover-background: #f2f2f2;
  --redsift-color-light-components-dropdowns-and-menus-hover-text: #333333;
  --redsift-color-light-components-dropdowns-and-menus-click-background: #333333;
  --redsift-color-light-components-dropdowns-and-menus-click-text: #ffffff;
  --redsift-color-light-components-dropdowns-and-menus-toggle-active-text: #ffffff;
  --redsift-color-light-components-dropdowns-and-menus-toggle-active-background: #4285f4;
  --redsift-color-light-components-dropdowns-and-menus-toggle-active-hover-text: #ffffff;
  --redsift-color-light-components-dropdowns-and-menus-toggle-active-hover-background: #3b78dc;
  --redsift-color-light-components-dropdowns-and-menus-toggle-active-click-text: #ffffff;
  --redsift-color-light-components-dropdowns-and-menus-toggle-active-click-background: #333333;
  --redsift-color-light-components-checkbox-fill-default: #4285f4;
  --redsift-color-light-components-checkbox-fill-default-hover: #d9e7fd;
  --redsift-color-light-components-checkbox-fill-uncolored: #666666;
  --redsift-color-light-components-checkbox-fill-uncolored-hover: #474747;
  --redsift-color-light-components-checkbox-fill-disabled: #e0e0e0;
  --redsift-color-light-components-checkbox-fill-invalid: #e11010;
  --redsift-color-light-components-checkbox-fill-invalid-hover: #f9cfcf;
  --redsift-color-light-components-checkbox-text-default: #333333;
  --redsift-color-light-components-checkbox-text-invalid: #e11010;
  --redsift-color-light-components-checkbox-text-disabled: #e0e0e0;
  --redsift-color-light-components-radio-fill-default: #4285f4;
  --redsift-color-light-components-radio-fill-default-hover: #d9e7fd;
  --redsift-color-light-components-radio-fill-uncolored: #666666;
  --redsift-color-light-components-radio-fill-uncolored-hover: #474747;
  --redsift-color-light-components-radio-fill-disabled: #e0e0e0;
  --redsift-color-light-components-radio-fill-invalid: #e11010;
  --redsift-color-light-components-radio-fill-invalid-hover: #f9cfcf;
  --redsift-color-light-components-radio-text-default: #333333;
  --redsift-color-light-components-radio-text-invalid: #e11010;
  --redsift-color-light-components-radio-text-disabled: #e0e0e0;
  --redsift-color-light-components-switch-dot-default: #ffffff;
  --redsift-color-light-components-switch-dot-default-hover: #d9e7fd;
  --redsift-color-light-components-switch-dot-uncolored: #ffffff;
  --redsift-color-light-components-switch-dot-uncolored-hover: #474747;
  --redsift-color-light-components-switch-dot-disabled: #ffffff;
  --redsift-color-light-components-switch-dot-invalid: #ffffff;
  --redsift-color-light-components-switch-dot-invalid-hover: #f9cfcf;
  --redsift-color-light-components-switch-background-default: #d9e7fd;
  --redsift-color-light-components-switch-background-default-active: #4285f4;
  --redsift-color-light-components-switch-background-uncolored: #474747;
  --redsift-color-light-components-switch-background-uncolored-active: #666666;
  --redsift-color-light-components-switch-background-disabled: #e0e0e0;
  --redsift-color-light-components-switch-background-disabled-active: #666666;
  --redsift-color-light-components-switch-background-invalid: #f9cfcf;
  --redsift-color-light-components-switch-background-invalid-active: #e11010;
  --redsift-color-light-components-switch-text-default: #333333;
  --redsift-color-light-components-switch-text-invalid: #e11010;
  --redsift-color-light-components-switch-text-disabled: #e0e0e0;
  --redsift-color-light-components-tabs-line: #e0e0e0;
  --redsift-color-light-components-modal-background: #ffffff;
  --redsift-color-light-components-pills-grey-default-background: #e6e6e6;
  --redsift-color-light-components-pills-grey-default-border: transparent;
  --redsift-color-light-components-pills-grey-default-text: #1c1c1c;
  --redsift-color-light-components-pills-grey-hover-background: #d6d6d6;
  --redsift-color-light-components-pills-grey-hover-border: transparent;
  --redsift-color-light-components-pills-grey-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-grey-disabled-background: #eeeeee;
  --redsift-color-light-components-pills-grey-disabled-border: transparent;
  --redsift-color-light-components-pills-grey-disabled-text: #666666;
  --redsift-color-light-components-pills-green-default-background: #a2e7ba;
  --redsift-color-light-components-pills-green-default-border: transparent;
  --redsift-color-light-components-pills-green-default-text: #1c1c1c;
  --redsift-color-light-components-pills-green-hover-background: #56d58e;
  --redsift-color-light-components-pills-green-hover-border: transparent;
  --redsift-color-light-components-pills-green-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-green-disabled-background: #c2efd1;
  --redsift-color-light-components-pills-green-disabled-border: transparent;
  --redsift-color-light-components-pills-green-disabled-text: #666666;
  --redsift-color-light-components-pills-pink-default-background: #eca0d6;
  --redsift-color-light-components-pills-pink-default-border: transparent;
  --redsift-color-light-components-pills-pink-default-text: #1c1c1c;
  --redsift-color-light-components-pills-pink-hover-background: #d95cba;
  --redsift-color-light-components-pills-pink-hover-border: transparent;
  --redsift-color-light-components-pills-pink-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-pink-disabled-background: #f4c0e3;
  --redsift-color-light-components-pills-pink-disabled-border: transparent;
  --redsift-color-light-components-pills-pink-disabled-text: #666666;
  --redsift-color-light-components-pills-aqua-default-background: #9af8f4;
  --redsift-color-light-components-pills-aqua-default-border: transparent;
  --redsift-color-light-components-pills-aqua-default-text: #1c1c1c;
  --redsift-color-light-components-pills-aqua-hover-background: #63eae4;
  --redsift-color-light-components-pills-aqua-hover-border: transparent;
  --redsift-color-light-components-pills-aqua-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-aqua-disabled-background: #caf7f4;
  --redsift-color-light-components-pills-aqua-disabled-border: transparent;
  --redsift-color-light-components-pills-aqua-disabled-text: #666666;
  --redsift-color-light-components-pills-brown-default-background: #edc19c;
  --redsift-color-light-components-pills-brown-default-border: transparent;
  --redsift-color-light-components-pills-brown-default-text: #1c1c1c;
  --redsift-color-light-components-pills-brown-hover-background: #c78348;
  --redsift-color-light-components-pills-brown-hover-border: transparent;
  --redsift-color-light-components-pills-brown-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-brown-disabled-background: #f6d9c3;
  --redsift-color-light-components-pills-brown-disabled-border: transparent;
  --redsift-color-light-components-pills-brown-disabled-text: #666666;
  --redsift-color-light-components-pills-red-default-background: #f3a39e;
  --redsift-color-light-components-pills-red-default-border: transparent;
  --redsift-color-light-components-pills-red-default-text: #1c1c1c;
  --redsift-color-light-components-pills-red-hover-background: #e06363;
  --redsift-color-light-components-pills-red-hover-border: transparent;
  --redsift-color-light-components-pills-red-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-red-disabled-background: #f8bbba;
  --redsift-color-light-components-pills-red-disabled-border: transparent;
  --redsift-color-light-components-pills-red-disabled-text: #666666;
  --redsift-color-light-components-pills-yellow-default-background: #f7efc3;
  --redsift-color-light-components-pills-yellow-default-border: transparent;
  --redsift-color-light-components-pills-yellow-default-text: #1c1c1c;
  --redsift-color-light-components-pills-yellow-hover-background: #fff741;
  --redsift-color-light-components-pills-yellow-hover-border: transparent;
  --redsift-color-light-components-pills-yellow-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-yellow-disabled-background: #fbf5db;
  --redsift-color-light-components-pills-yellow-disabled-border: transparent;
  --redsift-color-light-components-pills-yellow-disabled-text: #666666;
  --redsift-color-light-components-pills-purple-default-background: #c6a8ef;
  --redsift-color-light-components-pills-purple-default-border: transparent;
  --redsift-color-light-components-pills-purple-default-text: #1c1c1c;
  --redsift-color-light-components-pills-purple-hover-background: #965ede;
  --redsift-color-light-components-pills-purple-hover-border: transparent;
  --redsift-color-light-components-pills-purple-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-purple-disabled-background: #decaf6;
  --redsift-color-light-components-pills-purple-disabled-border: transparent;
  --redsift-color-light-components-pills-purple-disabled-text: #666666;
  --redsift-color-light-components-pills-orange-default-background: #f8d296;
  --redsift-color-light-components-pills-orange-default-border: transparent;
  --redsift-color-light-components-pills-orange-default-text: #1c1c1c;
  --redsift-color-light-components-pills-orange-hover-background: #fcbb54;
  --redsift-color-light-components-pills-orange-hover-border: transparent;
  --redsift-color-light-components-pills-orange-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-orange-disabled-background: #fde4c0;
  --redsift-color-light-components-pills-orange-disabled-border: transparent;
  --redsift-color-light-components-pills-orange-disabled-text: #666666;
  --redsift-color-light-components-pills-blue-default-background: #b1dcf3;
  --redsift-color-light-components-pills-blue-default-border: transparent;
  --redsift-color-light-components-pills-blue-default-text: #1c1c1c;
  --redsift-color-light-components-pills-blue-hover-background: #73c5eb;
  --redsift-color-light-components-pills-blue-hover-border: transparent;
  --redsift-color-light-components-pills-blue-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-blue-disabled-background: #cce8f7;
  --redsift-color-light-components-pills-blue-disabled-border: transparent;
  --redsift-color-light-components-pills-blue-disabled-text: #666666;
  --redsift-color-light-components-pills-black-default-background: #1c1c1c;
  --redsift-color-light-components-pills-black-default-border: transparent;
  --redsift-color-light-components-pills-black-default-text: #ffffff;
  --redsift-color-light-components-pills-black-hover-background: #333333;
  --redsift-color-light-components-pills-black-hover-border: transparent;
  --redsift-color-light-components-pills-black-hover-text: #ffffff;
  --redsift-color-light-components-pills-black-disabled-background: #474747;
  --redsift-color-light-components-pills-black-disabled-border: transparent;
  --redsift-color-light-components-pills-black-disabled-text: #ffffff;
  --redsift-color-light-components-pills-x-dark-grey-default-background: #333333;
  --redsift-color-light-components-pills-x-dark-grey-default-border: transparent;
  --redsift-color-light-components-pills-x-dark-grey-default-text: #ffffff;
  --redsift-color-light-components-pills-x-dark-grey-hover-background: #474747;
  --redsift-color-light-components-pills-x-dark-grey-hover-border: transparent;
  --redsift-color-light-components-pills-x-dark-grey-hover-text: #ffffff;
  --redsift-color-light-components-pills-x-dark-grey-disabled-background: #666666;
  --redsift-color-light-components-pills-x-dark-grey-disabled-border: transparent;
  --redsift-color-light-components-pills-x-dark-grey-disabled-text: #ffffff;
  --redsift-color-light-components-pills-dark-grey-default-background: #474747;
  --redsift-color-light-components-pills-dark-grey-default-border: transparent;
  --redsift-color-light-components-pills-dark-grey-default-text: #ffffff;
  --redsift-color-light-components-pills-dark-grey-hover-background: #666666;
  --redsift-color-light-components-pills-dark-grey-hover-border: transparent;
  --redsift-color-light-components-pills-dark-grey-hover-text: #ffffff;
  --redsift-color-light-components-pills-dark-grey-disabled-background: #e0e0e0;
  --redsift-color-light-components-pills-dark-grey-disabled-border: transparent;
  --redsift-color-light-components-pills-dark-grey-disabled-text: #ffffff;
  --redsift-color-light-components-pills-mid-grey-default-background: #666666;
  --redsift-color-light-components-pills-mid-grey-default-border: transparent;
  --redsift-color-light-components-pills-mid-grey-default-text: #ffffff;
  --redsift-color-light-components-pills-mid-grey-hover-background: #474747;
  --redsift-color-light-components-pills-mid-grey-hover-border: transparent;
  --redsift-color-light-components-pills-mid-grey-hover-text: #ffffff;
  --redsift-color-light-components-pills-mid-grey-disabled-background: #e0e0e0;
  --redsift-color-light-components-pills-mid-grey-disabled-border: transparent;
  --redsift-color-light-components-pills-mid-grey-disabled-text: #666666;
  --redsift-color-light-components-pills-light-grey-default-background: #e0e0e0;
  --redsift-color-light-components-pills-light-grey-default-border: transparent;
  --redsift-color-light-components-pills-light-grey-default-text: #1c1c1c;
  --redsift-color-light-components-pills-light-grey-hover-background: #666666;
  --redsift-color-light-components-pills-light-grey-hover-border: transparent;
  --redsift-color-light-components-pills-light-grey-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-light-grey-disabled-background: #f2f2f2;
  --redsift-color-light-components-pills-light-grey-disabled-border: transparent;
  --redsift-color-light-components-pills-light-grey-disabled-text: #666666;
  --redsift-color-light-components-pills-x-light-grey-default-background: #f2f2f2;
  --redsift-color-light-components-pills-x-light-grey-default-border: transparent;
  --redsift-color-light-components-pills-x-light-grey-default-text: #1c1c1c;
  --redsift-color-light-components-pills-x-light-grey-hover-background: #e0e0e0;
  --redsift-color-light-components-pills-x-light-grey-hover-border: transparent;
  --redsift-color-light-components-pills-x-light-grey-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-x-light-grey-disabled-background: #666666;
  --redsift-color-light-components-pills-x-light-grey-disabled-border: transparent;
  --redsift-color-light-components-pills-x-light-grey-disabled-text: #666666;
  --redsift-color-light-components-pills-white-default-background: #ffffff;
  --redsift-color-light-components-pills-white-default-border: #e0e0e0;
  --redsift-color-light-components-pills-white-default-text: #1c1c1c;
  --redsift-color-light-components-pills-white-hover-background: #f2f2f2;
  --redsift-color-light-components-pills-white-hover-border: #e0e0e0;
  --redsift-color-light-components-pills-white-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-white-disabled-background: #ffffff;
  --redsift-color-light-components-pills-white-disabled-border: #e0e0e0;
  --redsift-color-light-components-pills-white-disabled-text: #666666;
  --redsift-color-light-components-pills-error-default-background: #f8bbba;
  --redsift-color-light-components-pills-error-default-border: transparent;
  --redsift-color-light-components-pills-error-default-text: #1c1c1c;
  --redsift-color-light-components-pills-error-hover-background: #e06363;
  --redsift-color-light-components-pills-error-hover-border: transparent;
  --redsift-color-light-components-pills-error-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-error-disabled-background: #f3a39e;
  --redsift-color-light-components-pills-error-disabled-border: transparent;
  --redsift-color-light-components-pills-error-disabled-text: #666666;
  --redsift-color-light-components-pills-warning-default-background: #fde4c0;
  --redsift-color-light-components-pills-warning-default-border: transparent;
  --redsift-color-light-components-pills-warning-default-text: #1c1c1c;
  --redsift-color-light-components-pills-warning-hover-background: #fcbb54;
  --redsift-color-light-components-pills-warning-hover-border: transparent;
  --redsift-color-light-components-pills-warning-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-warning-disabled-background: #f8d296;
  --redsift-color-light-components-pills-warning-disabled-border: transparent;
  --redsift-color-light-components-pills-warning-disabled-text: #666666;
  --redsift-color-light-components-pills-success-default-background: #c2efd1;
  --redsift-color-light-components-pills-success-default-border: transparent;
  --redsift-color-light-components-pills-success-default-text: #1c1c1c;
  --redsift-color-light-components-pills-success-hover-background: #56d58e;
  --redsift-color-light-components-pills-success-hover-border: transparent;
  --redsift-color-light-components-pills-success-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-success-disabled-background: #a2e7ba;
  --redsift-color-light-components-pills-success-disabled-border: transparent;
  --redsift-color-light-components-pills-success-disabled-text: #666666;
  --redsift-color-light-components-pills-error-dark-default-background: #e06363;
  --redsift-color-light-components-pills-error-dark-default-border: transparent;
  --redsift-color-light-components-pills-error-dark-default-text: #1c1c1c;
  --redsift-color-light-components-pills-error-dark-hover-background: #de0000;
  --redsift-color-light-components-pills-error-dark-hover-border: transparent;
  --redsift-color-light-components-pills-error-dark-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-error-dark-disabled-background: #f3a39e;
  --redsift-color-light-components-pills-error-dark-disabled-border: transparent;
  --redsift-color-light-components-pills-error-dark-disabled-text: #666666;
  --redsift-color-light-components-pills-warning-dark-default-background: #fcbb54;
  --redsift-color-light-components-pills-warning-dark-default-border: transparent;
  --redsift-color-light-components-pills-warning-dark-default-text: #1c1c1c;
  --redsift-color-light-components-pills-warning-dark-hover-background: #ed9200;
  --redsift-color-light-components-pills-warning-dark-hover-border: transparent;
  --redsift-color-light-components-pills-warning-dark-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-warning-dark-disabled-background: #f8d296;
  --redsift-color-light-components-pills-warning-dark-disabled-border: transparent;
  --redsift-color-light-components-pills-warning-dark-disabled-text: #666666;
  --redsift-color-light-components-pills-success-dark-default-background: #56d58e;
  --redsift-color-light-components-pills-success-dark-default-border: transparent;
  --redsift-color-light-components-pills-success-dark-default-text: #1c1c1c;
  --redsift-color-light-components-pills-success-dark-hover-background: #00ce59;
  --redsift-color-light-components-pills-success-dark-hover-border: transparent;
  --redsift-color-light-components-pills-success-dark-hover-text: #1c1c1c;
  --redsift-color-light-components-pills-success-dark-disabled-background: #a2e7ba;
  --redsift-color-light-components-pills-success-dark-disabled-border: transparent;
  --redsift-color-light-components-pills-success-dark-disabled-text: #666666;
  --redsift-color-red-l4: #f9cfcf;
  --redsift-color-red-l3: #f39f9f;
  --redsift-color-red-l2: #ed7070;
  --redsift-color-red-l1: #e74040;
  --redsift-color-red-n: #e11010;
  --redsift-color-red-d1: #cb0e0e;
  --redsift-color-red-d2: #9e0b0b;
  --redsift-color-red-d3: #430505;
  --redsift-color-red-d4: #2d0303;
  --redsift-color-blue-l4: #d9e7fd;
  --redsift-color-blue-l3: #b3cefb;
  --redsift-color-blue-l2: #8eb6f8;
  --redsift-color-blue-l1: #689df6;
  --redsift-color-blue-n: #4285f4;
  --redsift-color-blue-d1: #3b78dc;
  --redsift-color-blue-d2: #2e5dab;
  --redsift-color-blue-d3: #142849;
  --redsift-color-blue-d4: #0d1b31;
  --redsift-color-teal-l4: #dcf1ed;
  --redsift-color-teal-l3: #b9e2db;
  --redsift-color-teal-l2: #97d4c8;
  --redsift-color-teal-l1: #74c5b6;
  --redsift-color-teal-n: #51b7a4;
  --redsift-color-teal-d1: #49a594;
  --redsift-color-teal-d2: #398073;
  --redsift-color-teal-d3: #183731;
  --redsift-color-teal-d4: #102521;
  --redsift-color-grey-l5: #ffffff;
  --redsift-color-grey-l4: #f2f2f2;
  --redsift-color-grey-l3: #e0e0e0;
  --redsift-color-grey-l2: #a3a3a3;
  --redsift-color-grey-l1: #858585;
  --redsift-color-grey-n: #666666;
  --redsift-color-grey-d1: #5c5c5c;
  --redsift-color-grey-d2: #525252;
  --redsift-color-grey-d3: #474747;
  --redsift-color-grey-d4: #333333;
  --redsift-color-grey-d5: #1c1c1c;
  --redsift-color-green-l4: #cceedf;
  --redsift-color-green-l3: #9adec0;
  --redsift-color-green-l2: #67cda0;
  --redsift-color-green-l1: #35bd81;
  --redsift-color-green-n: #02ac61;
  --redsift-color-green-d1: #029b57;
  --redsift-color-green-d2: #017844;
  --redsift-color-green-d3: #01341d;
  --redsift-color-green-d4: #002213;
  --redsift-color-yellow-l4: #fef1dd;
  --redsift-color-yellow-l3: #fee4bb;
  --redsift-color-yellow-l2: #fdd698;
  --redsift-color-yellow-l1: #fdc976;
  --redsift-color-yellow-n: #fcbb54;
  --redsift-color-yellow-d1: #e3a84c;
  --redsift-color-yellow-d2: #b0833b;
  --redsift-color-yellow-d3: #4c3819;
  --redsift-color-yellow-d4: #322511;
  --redsift-color-primary-l4: #d9e7fd;
  --redsift-color-primary-l3: #b3cefb;
  --redsift-color-primary-l2: #8eb6f8;
  --redsift-color-primary-l1: #689df6;
  --redsift-color-primary-n: #4285f4;
  --redsift-color-primary-d1: #3b78dc;
  --redsift-color-primary-d2: #2e5dab;
  --redsift-color-primary-d3: #142849;
  --redsift-color-primary-d4: #0d1b31;
  --redsift-color-secondary-l4: #dcf1ed;
  --redsift-color-secondary-l3: #b9e2db;
  --redsift-color-secondary-l2: #97d4c8;
  --redsift-color-secondary-l1: #74c5b6;
  --redsift-color-secondary-n: #51b7a4;
  --redsift-color-secondary-d1: #49a594;
  --redsift-color-secondary-d2: #398073;
  --redsift-color-secondary-d3: #183731;
  --redsift-color-secondary-d4: #102521;
  --redsift-color-neutral-white: #ffffff;
  --redsift-color-neutral-x-light-grey: #f2f2f2;
  --redsift-color-neutral-light-grey: #e0e0e0;
  --redsift-color-neutral-disabled: #a3a3a3;
  --redsift-color-neutral-mid-grey: #666666;
  --redsift-color-neutral-dark-grey: #474747;
  --redsift-color-neutral-x-dark-grey: #333333;
  --redsift-color-neutral-black: #1c1c1c;
  --redsift-color-text-primary: #333333;
  --redsift-color-text-secondary: #474747;
  --redsift-color-product-asm: #8361ea;
  --redsift-color-product-brand-trust: #7ec2fa;
  --redsift-color-product-certificates: #e8712e;
  --redsift-color-product-hardenize: #3498db;
  --redsift-color-product-on-dmarc: #96d36e;
  --redsift-color-product-ondmarc: #96d36e;
  --redsift-color-product-on-inbox: #33b222;
  --redsift-color-product-oninbox: #33b222;
  --redsift-color-product-radar: #51b7a4;
  --redsift-color-product-vendor-secure: #f7c948;
  --redsift-color-product-rojo-ds: #e11010;
  --redsift-color-product-website: #e11010;
  --redsift-color-product-pulse: #e11010;
  --redsift-color-borders-border-divider: #f2f2f2;
  --redsift-color-borders-border-default: #666666;
  --redsift-color-borders-border-disabled: #e0e0e0;
  --redsift-color-borders-border-primary: #4285f4;
  --redsift-color-borders-border-secondary: #51b7a4;
  --redsift-color-borders-border-success: #02ac61;
  --redsift-color-borders-border-error: #e11010;
  --redsift-color-notifications-primary-primary: #4285f4;
  --redsift-color-notifications-primary-hover: #d9e7fd;
  --redsift-color-notifications-primary-active: #b3cefb;
  --redsift-color-notifications-secondary-primary: #51b7a4;
  --redsift-color-notifications-secondary-hover: #dcf1ed;
  --redsift-color-notifications-secondary-active: #b9e2db;
  --redsift-color-notifications-success-primary: #02ac61;
  --redsift-color-notifications-success-hover: #cceedf;
  --redsift-color-notifications-success-active: #9adec0;
  --redsift-color-notifications-error-primary: #e11010;
  --redsift-color-notifications-error-hover: #f9cfcf;
  --redsift-color-notifications-error-active: #f39f9f;
  --redsift-color-notifications-warning-primary: #fcbb54;
  --redsift-color-notifications-warning-hover: #fef1dd;
  --redsift-color-notifications-warning-active: #fee4bb;
  --redsift-color-notifications-info-primary: #4285f4;
  --redsift-color-notifications-info-hover: #d9e7fd;
  --redsift-color-notifications-info-active: #b3cefb;
  --redsift-color-notifications-question-primary: #666666;
  --redsift-color-notifications-question-hover: #474747;
  --redsift-color-notifications-question-active: #333333;
  --redsift-color-notifications-no-data-primary: #e0e0e0;
  --redsift-color-notifications-no-data-hover: #666666;
  --redsift-color-notifications-no-data-active: #474747;
  --redsift-color-presentation-green-darkerer: #078833;
  --redsift-color-presentation-green-darker: #07aa45;
  --redsift-color-presentation-green-dark: #00ce59;
  --redsift-color-presentation-green-default: #56d58e;
  --redsift-color-presentation-green-light: #a2e7ba;
  --redsift-color-presentation-green-lighter: #c2efd1;
  --redsift-color-presentation-green-lighterer: #e1f7e8;
  --redsift-color-presentation-pink-darkerer: #b10078;
  --redsift-color-presentation-pink-darker: #c5008c;
  --redsift-color-presentation-pink-dark: #d800a2;
  --redsift-color-presentation-pink-default: #d95cba;
  --redsift-color-presentation-pink-light: #eca0d6;
  --redsift-color-presentation-pink-lighter: #f4c0e3;
  --redsift-color-presentation-pink-lighterer: #fae0f1;
  --redsift-color-presentation-aqua-darkerer: #0b9287;
  --redsift-color-presentation-aqua-darker: #0ab5ab;
  --redsift-color-presentation-aqua-dark: #00d9d2;
  --redsift-color-presentation-aqua-default: #63eae4;
  --redsift-color-presentation-aqua-light: #9af8f4;
  --redsift-color-presentation-aqua-lighter: #caf7f4;
  --redsift-color-presentation-aqua-lighterer: #e5fbfa;
  --redsift-color-presentation-brown-darkerer: #701205;
  --redsift-color-presentation-brown-darker: #903107;
  --redsift-color-presentation-brown-dark: #af5100;
  --redsift-color-presentation-brown-default: #c78348;
  --redsift-color-presentation-brown-light: #edc19c;
  --redsift-color-presentation-brown-lighter: #f6d9c3;
  --redsift-color-presentation-brown-lighterer: #fae6d7;
  --redsift-color-presentation-red-darkerer: #b10004;
  --redsift-color-presentation-red-darker: #c70003;
  --redsift-color-presentation-red-dark: #de0000;
  --redsift-color-presentation-red-default: #e06363;
  --redsift-color-presentation-red-light: #f3a39e;
  --redsift-color-presentation-red-lighter: #f8bbba;
  --redsift-color-presentation-red-lighterer: #fddddc;
  --redsift-color-presentation-yellow-darkerer: #8a7f18;
  --redsift-color-presentation-yellow-darker: #bcad14;
  --redsift-color-presentation-yellow-dark: #f0de00;
  --redsift-color-presentation-yellow-default: #fff741;
  --redsift-color-presentation-yellow-light: #f7efc3;
  --redsift-color-presentation-yellow-lighter: #fbf5db;
  --redsift-color-presentation-yellow-lighterer: #fcf9e7;
  --redsift-color-presentation-purple-darkerer: #4702c1;
  --redsift-color-presentation-purple-darker: #7902eb;
  --redsift-color-presentation-purple-dark: #9200ff;
  --redsift-color-presentation-purple-default: #965ede;
  --redsift-color-presentation-purple-light: #c6a8ef;
  --redsift-color-presentation-purple-lighter: #decaf6;
  --redsift-color-presentation-purple-lighterer: #e9dcf9;
  --redsift-color-presentation-orange-darkerer: #b45310;
  --redsift-color-presentation-orange-darker: #d2710b;
  --redsift-color-presentation-orange-dark: #ed9200;
  --redsift-color-presentation-orange-default: #fcbb54;
  --redsift-color-presentation-orange-light: #f8d296;
  --redsift-color-presentation-orange-lighter: #fde4c0;
  --redsift-color-presentation-orange-lighterer: #feedd5;
  --redsift-color-presentation-blue-darkerer: #0082f8;
  --redsift-color-presentation-blue-darker: #0099fd;
  --redsift-color-presentation-blue-dark: #00aeff;
  --redsift-color-presentation-blue-default: #73c5eb;
  --redsift-color-presentation-blue-light: #b1dcf3;
  --redsift-color-presentation-blue-lighter: #cce8f7;
  --redsift-color-presentation-blue-lighterer: #e6f3fb;
  --redsift-color-presentation-grey-darkerer: #6e6e6e;
  --redsift-color-presentation-grey-darker: #959595;
  --redsift-color-presentation-grey-dark: #bfbfbf;
  --redsift-color-presentation-grey-default: #d6d6d6;
  --redsift-color-presentation-grey-light: #e6e6e6;
  --redsift-color-presentation-grey-lighter: #eeeeee;
  --redsift-color-presentation-grey-lighterer: #f7f7f7;
  --redsift-border-radius: 4px;
  --redsift-layout-z-index-footer: 1000;
  --redsift-layout-z-index-header: 1010;
  --redsift-layout-z-index-side-panel: 1050;
  --redsift-layout-z-index-dropdown: 1100;
  --redsift-layout-z-index-overlay: 1150;
  --redsift-layout-z-index-dialog: 1200;
  --redsift-layout-z-index-popover: 1250;
  --redsift-layout-z-index-tooltip: 1300;
  --redsift-typography-font-family-poppins: 'Poppins', sans-serif;
  --redsift-typography-font-family-source-code-pro: 'Source Code Pro', sans-serif;
  --redsift-typography-font-weight-thin: 100;
  --redsift-typography-font-weight-extra-light: 200;
  --redsift-typography-font-weight-light: 300;
  --redsift-typography-font-weight-regular: 400;
  --redsift-typography-font-weight-medium: 500;
  --redsift-typography-font-weight-semi-bold: 600;
  --redsift-typography-font-weight-bold: 700;
  --redsift-typography-font-weight-extra-bold: 800;
  --redsift-typography-font-weight-black: 900;
  --redsift-typography-h1-font-family: 'Poppins', sans-serif;
  --redsift-typography-h1-font-size: 24px;
  --redsift-typography-h1-font-weight: 600;
  --redsift-typography-h1-line-height: 36px;
  --redsift-typography-h1-text-transform: unset;
  --redsift-typography-h2-font-family: 'Poppins', sans-serif;
  --redsift-typography-h2-font-size: 22px;
  --redsift-typography-h2-font-weight: 600;
  --redsift-typography-h2-line-height: 30px;
  --redsift-typography-h2-text-transform: unset;
  --redsift-typography-h3-font-family: 'Poppins', sans-serif;
  --redsift-typography-h3-font-size: 20px;
  --redsift-typography-h3-font-weight: 500;
  --redsift-typography-h3-line-height: 30px;
  --redsift-typography-h3-text-transform: unset;
  --redsift-typography-h4-font-family: 'Poppins', sans-serif;
  --redsift-typography-h4-font-size: 16px;
  --redsift-typography-h4-font-weight: 600;
  --redsift-typography-h4-line-height: 24px;
  --redsift-typography-h4-text-transform: unset;
  --redsift-typography-h5-font-family: 'Poppins', sans-serif;
  --redsift-typography-h5-font-size: 12px;
  --redsift-typography-h5-font-weight: 600;
  --redsift-typography-h5-line-height: 18px;
  --redsift-typography-h5-text-transform: unset;
  --redsift-typography-body-font-family: 'Poppins', sans-serif;
  --redsift-typography-body-font-size: 15px;
  --redsift-typography-body-font-weight: 400;
  --redsift-typography-body-line-height: 24px;
  --redsift-typography-body-text-transform: unset;
  --redsift-typography-link-font-family: 'Poppins', sans-serif;
  --redsift-typography-link-font-size: 15px;
  --redsift-typography-link-font-weight: 400;
  --redsift-typography-link-line-height: 26px;
  --redsift-typography-input-text-font-family: 'Poppins', sans-serif;
  --redsift-typography-input-text-font-size: 15px;
  --redsift-typography-input-text-font-weight: 400;
  --redsift-typography-input-text-line-height: 24px;
  --redsift-typography-button-font-family: 'Poppins', sans-serif;
  --redsift-typography-button-font-size: 16px;
  --redsift-typography-button-font-weight: 500;
  --redsift-typography-button-line-height: 24px;
  --redsift-typography-button-text-transform: uppercase;
  --redsift-typography-caption-font-family: 'Poppins', sans-serif;
  --redsift-typography-caption-font-size: 13px;
  --redsift-typography-caption-font-weight: 400;
  --redsift-typography-caption-line-height: 18px;
  --redsift-typography-caption-text-transform: unset;
  --redsift-typography-helper-font-family: 'Poppins', sans-serif;
  --redsift-typography-helper-font-size: 16px;
  --redsift-typography-helper-font-weight: 400;
  --redsift-typography-helper-line-height: 24px;
  --redsift-typography-helper-text-transform: unset;
  --redsift-typography-badge-font-family: 'Poppins', sans-serif;
  --redsift-typography-badge-font-size: 12px;
  --redsift-typography-badge-font-weight: 400;
  --redsift-typography-badge-line-height: 12px;
  --redsift-typography-pill-font-family: 'Poppins', sans-serif;
  --redsift-typography-pill-font-size: 10px;
  --redsift-typography-pill-font-weight: 400;
  --redsift-typography-pill-line-height: 20px;
  --redsift-typography-chip-font-family: 'Poppins', sans-serif;
  --redsift-typography-chip-font-size: 12px;
  --redsift-typography-chip-font-weight: 400;
  --redsift-typography-chip-line-height: 22px;
  --redsift-typography-tooltip-font-family: 'Poppins', sans-serif;
  --redsift-typography-tooltip-font-size: 12px;
  --redsift-typography-tooltip-font-weight: 400;
  --redsift-typography-tooltip-line-height: 18px;
  --redsift-typography-datagrid-header-font-family: 'Poppins', sans-serif;
  --redsift-typography-datagrid-header-font-size: 14px;
  --redsift-typography-datagrid-header-font-weight: 600;
  --redsift-typography-datagrid-header-line-height: 21px;
  --redsift-typography-datagrid-cell-font-family: 'Poppins', sans-serif;
  --redsift-typography-datagrid-cell-font-size: 16px;
  --redsift-typography-datagrid-cell-font-weight: 400;
  --redsift-typography-datagrid-cell-line-height: 24px;
}
/* Reseting CSS. */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* * {
  box-sizing: border-box;
} */
/* Applying Design System style to native HTML tags. */

body {
  font-family: var(--redsift-typography-body-font-family);
  font-size: var(--redsift-typography-body-font-size);
  font-weight: var(--redsift-typography-body-font-weight);
  line-height: var(--redsift-typography-body-line-height);
  margin: unset;
  color: var(--redsift-color-text-primary);
}

h1 {
  font-family: var(--redsift-typography-h1-font-family);
  font-size: var(--redsift-typography-h1-font-size);
  font-weight: var(--redsift-typography-h1-font-weight);
  line-height: var(--redsift-typography-h1-line-height);
  color: var(--redsift-color-text-primary);
}

h2 {
  font-family: var(--redsift-typography-h2-font-family);
  font-size: var(--redsift-typography-h2-font-size);
  font-weight: var(--redsift-typography-h2-font-weight);
  line-height: var(--redsift-typography-h2-line-height);
  color: var(--redsift-color-text-primary);
}

h3 {
  font-family: var(--redsift-typography-h3-font-family);
  font-size: var(--redsift-typography-h3-font-size);
  font-weight: var(--redsift-typography-h3-font-weight);
  line-height: var(--redsift-typography-h3-line-height);
  color: var(--redsift-color-text-primary);
}

h4 {
  font-family: var(--redsift-typography-h4-font-family);
  font-size: var(--redsift-typography-h4-font-size);
  font-weight: var(--redsift-typography-h4-font-weight);
  line-height: var(--redsift-typography-h4-line-height);
  color: var(--redsift-color-text-primary);
}

a {
  background: none;
  border: none;
  color: var(--redsift-color-primary-n);
  font-family: var(--redsift-typography-link-font-family);
  font-size: var(--redsift-typography-link-font-size);
  font-weight: var(--redsift-typography-link-font-weight);
  line-height: var(--redsift-typography-link-line-height);
  padding: unset;
  text-decoration: underline;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  text-underline-offset: 2px;
}

a:hover,
a:focus-visible {
  cursor: pointer;
  -webkit-text-decoration-color: var(--redsift-color-primary-n);
          text-decoration-color: var(--redsift-color-primary-n);
}

a:focus-visible {
  outline: 2px solid var(--redsift-color-primary-n);
}

strong {
  font-weight: bold;
}

code,
pre,
kbd,
samp {
  font-family: var(--redsift-typography-font-family-source-code-pro);
}

b {
  font-weight: bold;
}

i,
cite,
em {
  font-style: italic;
}

sub,
sup {
  font-size: 12px;
}

sub {
  vertical-align: sub;
}

sup {
  vertical-align: super;
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_1c85f0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_1c85f0';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_1c85f0';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_1c85f0 {font-family: '__Poppins_1c85f0', '__Poppins_Fallback_1c85f0';font-weight: 400;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/1a142ec2652f2d06-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_d82496';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/dba81c1208da12ee-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Code_Pro_Fallback_d82496';src: local("Arial");ascent-override: 72.39%;descent-override: 20.08%;line-gap-override: 0.00%;size-adjust: 135.93%
}.__className_d82496 {font-family: '__Source_Code_Pro_d82496', '__Source_Code_Pro_Fallback_d82496';font-style: normal
}

